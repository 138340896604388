.priority_checkbox:checked ~ span {
  color: #ffffff;
  background-color: #f9ce47;
}

.image_full {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
